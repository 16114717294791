import React from 'react'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
import { ABOUTUS } from '../../components/constant'
const LocalChapters = () => {
  return (
    <div>
      <Layout>
        <SEO title="Local Chapters" />
        <PageStructure title="Local Chapters" desc="TESTIG" mode={ABOUTUS} content={`
        <b style="font-family:'Volkhov';">Current Office Bearers</b> <br/><br/>
        Under the aegis of the overarching All India Body elected annually are the Regional/Local Chapters of the Association. In all there are about 20 Regional chapters, at least one under each PrCCIT Region. Any station with more than 10 members can have its own local chapter. The members of the regional chapter are annually elected from among the IRSA members posted in the relevant region or at the concerned station. The organizational set up of each Regional Chapter is as follows
        <ol>
          <li>Chairman</li>
          <li>Vice Chairman</li>
          <li>Secretary</li>
          <li>Joint Secretaries</li>
          <li>Treasurer</li>
          <li>Elected Members (two posts)</li>
        </ol>

        <br/>

        The local units are the connecting link between the members and the Central Executive Committee (the ALL India Body). However, the individual members can also directly approach the Central Executive Committee if they wish to do so.

        <br/><br/>
        <b style="font-family:'Volkhov';">Chapter wise list of Office Bearers</b>


        `}/>
      </Layout>
    </div>
  )
}

export default LocalChapters
